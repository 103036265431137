import * as Sentry from '@sentry/sveltekit';
import { dev } from '$app/environment';
import type { ClientInit } from '@sveltejs/kit';

Sentry.init({
  enabled: !dev,
  dsn: 'https://cdded3d11e7cdfdce0a0a28a78c5c750@o4508636857761792.ingest.us.sentry.io/4508636905275392',
  autoSessionTracking: false,
  tracesSampleRate: 100.0
});

// If you have a custom error handler, pass it to `handleErrorWithSentry`
export const handleError = Sentry.handleErrorWithSentry();

export const init: ClientInit = async () => {};
