import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62')
];

export const server_loads = [];

export const dictionary = {
		"/": [12],
		"/about": [13,[2]],
		"/about/board": [14,[2]],
		"/about/careers": [15,[2]],
		"/about/contact": [16,[2]],
		"/about/privacy": [17,[2]],
		"/about/publications": [18,[2]],
		"/about/team": [19,[2]],
		"/account/forgot": [20,[3]],
		"/account/reset": [21,[3]],
		"/account/signin": [22,[3]],
		"/account/signout": [23,[3]],
		"/account/signup": [24,[3]],
		"/cart": [25,[4]],
		"/cart/checkout": [26,[4]],
		"/cart/complete": [27,[4]],
		"/donate": [28],
		"/donate/special-events": [29],
		"/donation": [~30],
		"/events/all-events": [32],
		"/events/[slug]": [~31],
		"/films": [33],
		"/films/anne-spencer-house-and-garden-museum": [34],
		"/films/blithewood-garden": [35],
		"/films/garden-in-conversation": [36],
		"/films/louise-wrinkle-garden": [37],
		"/garden-directory/open-days": [38],
		"/garden-directory/open-days/[slug]": [~39],
		"/gift-memberships": [~40],
		"/gift-memberships/[slug]": [~41],
		"/memberships": [~42],
		"/memberships/[slug]": [~43],
		"/news": [44,[5]],
		"/news/pressroom": [46,[5]],
		"/news/[slug]": [45,[5]],
		"/open-days": [47,[6]],
		"/open-days/get-involved": [48,[6]],
		"/order/[slug]": [49],
		"/preservation": [50,[8]],
		"/preservation/advocacy": [51,[8]],
		"/preservation/gcnn": [52,[8]],
		"/preservation/gcnn/membership": [~53,[8,9]],
		"/preservation/partners": [54,[8]],
		"/preservation/partners/[slug]": [55,[8]],
		"/profile": [56,[10]],
		"/profile/addresses": [57,[10]],
		"/profile/edit": [58,[10]],
		"/profile/users/add": [59,[10]],
		"/profile/users/edit": [60,[10]],
		"/programs": [61,[11]],
		"/society-of-fellows": [~62]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
	init: client_hooks.init,
	reroute: (() => {}),
	transport: {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const hash = false;

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.js';